import { whenLibAvailable, loadJsonScript } from "./utils";

// Prevent Hubspot from triggering its own load
window.hsConversationsSettings ??= {
  loadImmediately: false,
};

export async function prepareChat() {
  const [hubspot] = await Promise.all([loadChatScript(), addInfoToHubspot()]);
  return hubspot;
}

export async function openChatWidget() {
  const hubspot = await loadChatWidget();
  if (!hubspot) return window.Alpine?.$modal?.open("hubspot-blocked");
  window.HubSpotConversations.widget.open();
}

let widgetPromise = null;
export async function loadChatWidget() {
  const hubspot = await prepareChat();
  if (!hubspot) return false;
  return (widgetPromise ??= new Promise((resolve) => {
    window.HubSpotConversations.on("widgetLoaded", () => resolve(hubspot));
    window.HubSpotConversations.widget.load();
  }));
}

let hubspotPromise = null;
/**
 *
 * @returns {boolean} whether the script was successfully loaded
 */
export function loadChatScript() {
  // biome-ignore lint/suspicious/noAsyncPromiseExecutor: legacy
  return (hubspotPromise ??= new Promise(async (resolve) => {
    if (window.HubSpotConversations) resolve(true);
    try {
      await import(
        /* webpackIgnore: true */ "https://js.hs-scripts.com/7804309.js"
      );
      resolve(await whenLibAvailable("HubSpotConversations"));
    } catch (e) {
      resolve(false);
    }
  }));
}
/**
 * Get Hubspot user identification token
 */
async function addInfoToHubspot() {
  if (window.hsConversationsSettings.loaded) return;
  const { user_type: userType = null } = loadJsonScript({
    selector: "#layerData",
  });
  if (userType === "anonymous") {
    return;
  }
  const response = await fetch("/user/hubspot/", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  window.hsConversationsSettings.loaded = true;
  if (!response.ok) {
    return console.error("could not fetch user Hubspot token");
  }

  const { token: identificationToken } = await response.json();

  if (!identificationToken) {
    return console.error("could not fetch user Hubspot token");
  }

  try {
    const jwt = atob(identificationToken.split(".")[1]);
    const identificationEmail = JSON.parse(jwt).sub || "";

    Object.assign(window.hsConversationsSettings, {
      identificationEmail,
      identificationToken,
    });
  } catch (e) {
    return console.error(e);
  }
}
