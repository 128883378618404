import {
  init as sentryInit,
  setContext,
  captureException as sentryCaptureException,
  browserTracingIntegration,
  replayIntegration,
} from "@sentry/browser";

async function initSentry(environment, release) {
  sentryInit({
    environment,
    release,
    dsn: "https://7cc0de6ef515459db0ee85769b6fab29@o385897.ingest.sentry.io/4504304200187904",
    allowUrls: [/https?:\/\/app\.silvr\.co/],
    denyUrls: [
      /https?:\/\/app\.silvr\.co\/backoffice/,
      /https?:\/\/app\.silvr\.co\/company-scoring/,
    ],
    ignoreErrors: [
      /^\?(<anonymous>)$/,
      "Cannot set properties of undefined (setting 'TOUCHZOOM')",
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.0,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.0,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    integrations: [browserTracingIntegration(), replayIntegration()],
  });

  const { user_type, company_name, is_impersonating } =
    window.$silvr.utils.loadJsonScript({ selector: "#layerData" });
  setContext("user", { user_type, company_name, is_impersonating });
}

/**
 * @params {Error} [error] - An Error object.  It's also possible to pass non-Error objects and strings.
 * Be aware when using non-Error objects that the resulting events in Sentry may be missing a stacktrace.
 * https://docs.sentry.io/platforms/javascript/usage/#capturing-errors
 */
function captureException(error) {
  if (!process.env.NODE_ENV !== "production")
    console.error(error.message, error.cause);
  sentryCaptureException(error);
}

export { initSentry, captureException };
