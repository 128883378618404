const isOpticoEnable = async () => {
  const MAX_RETRY = 10;
  let retry = 0;

  return await new Promise((resolve) => {
    const intervalID = setInterval(() => {
      const openCta = document.getElementById("optico_call_me_back_button");

      if (openCta) {
        clearInterval(intervalID);
        resolve(true);
      }

      retry++;

      if (retry >= MAX_RETRY) {
        clearInterval(intervalID);
        resolve(false);
      }
    }, 200);
  });
};

const dispatchState = async () => {
  window.dispatchEvent(
    new CustomEvent("optico:init", {
      bubbles: true,
      detail: {
        enable: await isOpticoEnable(),
      },
    }),
  );
};

const installOptico = () => {
  const container = document.getElementById("customer-callback");
  const opticoId = container?.dataset?.opticoId;

  if (!container && !opticoId) return false;

  const commonScript = document.createElement("script");
  commonScript.src = "//www.optico.fr/clientWebcallInterface.js";
  commonScript.defer = true;

  window.opticoWebcallId = opticoId;
  window.opticoWebcallContainer = container;
  const customScript = document.createElement("script");
  customScript.src = `//www.optico.fr/clientWebcall.js?hash=${opticoId}`;
  customScript.async = true;
  customScript.onload = dispatchState();

  document.body.appendChild(commonScript);
  document.body.appendChild(customScript);
};

export default installOptico;
